import React from 'react'
import Home from "./Home";
// import toast, { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "../../ScrollIntoView/ScrollToTop"
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import {isIOS, isAndroid, isBrowser} from 'react-device-detect';


export default function DownloadPage() {
    var Link = ""

    if (isIOS)  (
      Link = "https://apps.apple.com/us/app/konjo/id1515175481"
   
    )
   
    if (isAndroid)  (
      Link = "https://play.google.com/store/apps/details?id=com.Konjo.konjo"
   
    )
    if (isIOS || isAndroid) return (
        <div className= 'background-blue'>
        <center className= 'background-blue'>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <ScrollToTop />
        <Navbar/>
        <Home/>
        <Footer/>  
        </center>
        <meta http-equiv="refresh" content={"0; URL=" + Link} />
        </div>
    );   
    
    return (
        <div className= 'background-blue'>
        <center className= 'background-blue'>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <ScrollToTop />
        <Navbar/>
        <Home/>
        <Footer/>  
        </center>
        </div>
    )
}



