import React from 'react';
import './App.css';
import Home from "./Components/Pages/Home/HomePage";
import DownloadRedirect from "./Components/Pages/Home/DownloadPage";
import Terms from "./Components/Pages/TermsPrivacy/TermsofUsePage";
import Privacy from "./Components/Pages/TermsPrivacy/PrivacyPage";
import Advertisement from "./Components/Pages/Advertisement/AdvertisementPage";

import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';



function App() {

  return (
    <div className= 'background-blue'> 
    <Router>
           <Switch>
             <Route path='/' component={Home} exact/>
             <Route path='/terms' component={Terms} exact/>
             <Route path='/privacy' component={Privacy} exact/>
             <Route path="/findhabeshalove" component={DownloadRedirect} exact />   
             <Route path="/download" component={DownloadRedirect} exact />
             <Route path="/advertisement" component={Advertisement} exact />

          </Switch>   
    </Router>
    </div>  

  );
}

export default App;