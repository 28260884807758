import React from 'react'
import './termsprivacy.css';
import Container from "@material-ui/core/Container";

export default function Privacy() {
    return (
        <Container component="main" maxWidth="sm">

        <div style={{margin: "auto", width: "100%", paddingTop: "25px"}}>
  
        <center>
            {/* <img src= {Logo}  width="100" height="100" alt="pic" /> <br/> */}
        </center>
  
        <h1 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"center"}}> Privacy Policy</h1>

        <br></br>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left"}}> This Policy describes how EDR ("us," "we" or “Konjo”) uses and discloses information of you when you access or use the Konjo mobile application whether through a mobile device or computer (collectively, the “Service”). This Policy is governed by and part of a Service Agreement. Please read this Policy and the Service Agreement carefully. We encourage you to read this Privacy Policy carefully when using our Services or transacting business with us. By using our Service, you are accepting the practices described in this Privacy Policy. WE DO NOT PERFORM CRIMINAL BACKGROUND CHECKS. </h3>
  
        <h1 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Collection of Information</h1>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> When you download and use the App, we may collect certain information (“Registration Information”) about you, such as:</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Gender identity;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Photographs;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Date of Birth;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Phone Number;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Email;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Information about yourself in the bio;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Your mobile device’s geographic location while our application is actively running.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> You will also be required to set-up your sexual preference. Once you register, you will be able to review and change this information at any time.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> This information helps to enhance the App and verify our Users. Registration Information such as your photo may be visible to the public.</h3>
        
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Registration Information</h3>
  
        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Cookies</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Cookies are small data files that are transferred to your computer’s hard disk. Like many websites, we use “cookie” technology to collect additional website usage data and to improve Konjo, but we do not require cookies for many parts of our App such as searching and looking at public User profiles or lists.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> The cookies we collect enable us to learn how people interact with Konjo, which in turn helps us make a better product for you. Cookies store information about your website visits and can recognise you and your preferences each time you visit Konjo, helping us provide a better service.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> If for any reason you decide that you do not want all of your Konjo activities to be stored you may set your browser and mobile settings to block cookies and local storage devices. Please remember that if you do so, you may not be able to access all of the features Konjo offers.</h3>
  
        <br></br>
     
        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> How We Use Collected Information</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Konjo may collect and use a User’s personal information for the following purposes:</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to deliver and improve our products and services, and manage our business;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to manage your account and provide you with customer support;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to perform research and analysis about your use of, or interest in, our or others’ products, services, or content;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to communicate with you by email, postal mail, telephone and/or mobile devices about products or services that may be of interest to you either from us or other third parties;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to develop, display, and track content and advertising tailored to your interests on our Service and other sites, including providing our advertisements to you when you visit other sites;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - for website or mobile application analytics;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to verify your eligibility and deliver prizes in connection with contests and sweepstakes;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to enforce or exercise any rights in our Terms of Use;</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> - to perform functions or services as otherwise described to you at the time of collection.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> With Whom We Share Your Information</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> 1. Disclosure to Your Matches: As an integral part of providing the Konjo service, we will disclose your profile information to your matches. Photos and other profile information posted by you will be available to subscribers that are matched with you. We may also make features available enabling your matches to share your profile information, including photos, with members of their social network(s). We never share your contact information with your matches.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> 2. Disclosure By Law: You acknowledge and agree that we may disclose information you provide if required to do so by law, at the request of a third party, or if we, in our sole discretion, believe that disclosure is reasonable to comply with the law, requests or orders from law enforcement, or any legal process (whether or not such disclosure is required by applicable law); protect or defend Konjo’s, or a third party's, rights or property; or protect someone's health or safety, such as when harm or violence against any person (including you) is threatened.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> 3. Disclosure to Protect Abuse Victims: Notwithstanding any other provision of this privacy statement or our Terms of Service, we reserve the right, but have no obligation, to disclose any information that you submit to the Services, if in our sole opinion, we suspect or have reason to suspect, that the information involves a party who may be the victim of abuse in any form. Abuse may include, without limitation, elder abuse, child abuse, spousal abuse, neglect, or domestic violence. Information may be disclosed to authorities that we, in our sole discretion, deem appropriate to handle such disclosure. Appropriate authorities may include, without limitation, law enforcement agencies, child protection agencies, or court officials. You hereby acknowledge and agree that we are permitted to make such disclosure.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> 4. Disclosure to Trusted Third Parties by Us: We may share your non-PII with third parties, but not in a manner that would reveal your identity. We may share your PII, sometimes in conjunction with your non-PII, with service providers that may be associated with us to perform functions on our behalf. For example, outsourced customer care agents or technology assistants may need access to your information to perform services for you. Your information will be treated as private and confidential by such service providers and not used for any other purpose than we authorize. In addition, from time to time, we may share PII (such as e-mail) about our user base with carefully selected third parties, so they can offer goods and services that we believe may be of interest to our users. If you do not wish to receive offers from our trusted partners, you can change your e-mail preferences at any time.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> 5. Disclosure to Trusted Third Parties at Your Request: When purchasing an Konjo subscription or while visiting our Services, we may provide you with advertisements, promotions and offers from third party providers. If you choose to accept any such offers, you may either (depending on the situation) directly provide your information in connection with the offer to such third party provider, or we will provide your information, including billing information, to the third party provider by pre-populating the offer registration forms. Your information will not be transferred until you actually accept the offer. You may opt-out of the offer at any time up until this point. Please refer to the third party provider's own privacy policy (provided on the offer pages) if you have any questions regarding how your information is used by such providers. Please be aware that these offers may be presented on pages framed by a Konjo Service. We do this to provide a seamless experience. Although these offer pages have the look and feel of our websites, you will be submitting your information directly to the third-party advertiser. You agree that we will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings, including the sharing of the information you supply to us with third party providers described here, or as the result of the presence of such providers on a Konjo Service.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Disclosure for Accounts Payable or Collections</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> It is your duty to make sure that you keep your account(s) in good standing with us. If you have an outstanding balance, or if you dispute you payment to us with your credit card or financial institution for any reason, please note that all information collected from your activity on our website may be disclosed to the bank and/or collections agency we work with for the purposes of establishing proof of user's identify, proof of use, proof that service was provided, and / or collecting any outstanding debt owed to us.</h3>

        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> How You Can Access Your Information</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> We give you the opportunity to modify personal information or demographic information you have provided to us at any time. Please be aware that it may take several hours for the changes you make to take effect on the public areas of the Konjo Service. We thank you for your patience. Please note that changing or deleting your information, will only change or delete the data in our database for purposes of future activities on the Services and for managing future communications from Konjo.</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> These changes and deletions will not change or delete emails, messages or information that we may have already forwarded to other users or credit card companies or any other third parties, all as provided above in this privacy policy.</h3>

        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> How We Protect Your Personal Information</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Konjo takes reasonable measures in an effort to prevent the loss, misuse, and alteration of the information that we obtain from you, but we make no assurances about our ability to prevent any such loss, misuse or alteration, and we will have no liability to you or to any third party arising out of any such loss, misuse or alteration. Konjo may store and process personal information in the United States and other countries.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Information You Provide about Yourself While Using Our Service</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Whenever you voluntarily disclose personal information on publicly-viewable pages, that information will be publicly available and can be collected and used by others. For example, if you post your email address, you may receive unsolicited messages. We cannot control who reads your posting or what other users may do with the information you voluntarily post, so we encourage you to exercise discretion and caution with respect to your personal information.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Data Retention</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> Because many users tend to use our service at different points of their relationship-seeking life, we retain your personal information for continued service and convenience purposes until you advise us not to do so. Notwithstanding the foregoing, we may retain personal information in order to comply with applicable laws, keep accurate records, resolve disputes, prevent fraud, enforce our Terms or other agreements, or for any other legally permissible purpose.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Children's Privacy</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> We require users to be at least 18 years of age. We do not intend for anyone under the age of 18 to use any of the features or services we offer from Konjo Services, and we certainly do not direct any of our content specifically at children under 18 years of age. We understand and are committed to respecting the sensitive nature of children's privacy online.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> Changes to This Privacy Policy</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> We reserve the right, at any time, to add to, change, update, or modify this Policy, simply by posting such change, update, or modification on Konjo Services and without any other notice to you. Any such change, update, or modification will be effective immediately upon posting on the Services. If any changes are made to this Privacy Policy we will reflect the "Last Revised" date above. It is your responsibility to review this Policy from time to time to ensure that you continue to agree with all of its terms.</h3>
  
        <br></br>

        <h3 style={{color: "white", fontSize: 25, fontFamily:"Neuton", textAlign:"left", paddingLeft: "15px"}}> How to Contact Us</h3>
  
        <h3 style={{color: "white", fontSize: 15, fontFamily:"Neuton", textAlign:"left", paddingLeft: "25px"}}> If you have any questions about this Policy, our practices related to the Konjo Services, or if you would like to have us remove your information from our database, where possible, please feel free to contact us at konjo.official@gmail.com with subject line "Privacy Policy".</h3>
    
        </div >
        </Container>
    )
}