import React from 'react';
import './Home.css';
import { Container } from "reactstrap";
import AppStoreButton from '../../Images/AppStore.png';
import GooglePlayButton from '../../Images/GooglePlay.png';
import First from '../../Images/First.png';
import Second from '../../Images/Second.png';
import Third from '../../Images/Third.png';
import Fourth from '../../Images/Fourth.png';
import Fifth from '../../Images/Fifth.png';
import Sixth from '../../Images/Sixth.png';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const photos = [
    {
      name: 'Photo 1',
      url: First
    },
    {
      name: 'Photo 2',
      url: Second
    },
    {
      name: 'Photo 3',
      url: Third
    },
    {
      name: 'Photo 4',
      url: Fourth
    },
    {
      name: 'Photo 5',
      url: Fifth
    },
    {
      name: 'Photo 6',
      url: Sixth
    }
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    adaptiveHeight: true
    };

function Home() {

    return (
        <Container component="main" maxWidth="xs">
        <div id = 'About-Me'>
            <div>
                <center className= 'background-blue'>
                    <h1 className='fa-times'>
                        Konjo Dating App 🌻
                    </h1>
                    <h4 className="fa-small">
                        🇪🇹✨🇪🇷 Find your perfect match today (18+) ⤵️
                    </h4>

                    <a href= "https://apps.apple.com/us/app/konjo/id1515175481">
                        <img src= {AppStoreButton} width="125px" height="60px" style={{paddingRight: "5px", paddingTop: "5px", paddingBottom: "5px"}} />
                    </a>
                    <a href= "https://play.google.com/store/apps/details?id=com.Konjo.konjo">
                        <img src= {GooglePlayButton} width="125px" height="60px" style={{paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px"}}  />
                    </a>
                        <Slider {...settings} style={{ maxWidth: "600px"}}>
                        {photos.map((photo) => {
                            return(
                            <div>
                            <img width="100%" src={photo.url}/>
                            </div>
                            )
                        })}
                        </Slider>
                        <ReactPlayer controls url={['https://youtu.be/whgJjwHGnPQ','https://youtu.be/JeoK1CWA1vc','https://youtu.be/8Z8jtMc40es','https://youtu.be/pp5qvrN4POk']}
       width= '100%' style = {{ paddingTop: "25px"}}/>
                    {/* <ReactPlayer  controls url={['https://youtu.be/whgJjwHGnPQ','https://youtu.be/JeoK1CWA1vc','https://youtu.be/8Z8jtMc40es','https://youtu.be/pp5qvrN4POk'] } /> */}
                </center>
            </div>
        </div>
        </Container>
    );
}

export default Home;