import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import Logo from '../Images/KonjoImage.png';
import './Navbar.css';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";


import {IconContext} from 'react-icons/lib';


function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)
  
  return(
    <>
    <IconContext.Provider value={{color: '#fff'}}>
      <div className='navbar' >
          <div className='navbar-container container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img className= 'navbar-icon' width='50px' src={Logo}/>    
        Konjo Dating App 🌻
        </Link>

          <div className='menu-icon' onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item' onClick={handleClick}>
              <Link to='/' className='nav-links' onClick={handleClick}>
                Download 
              </Link>
            </li>
            <li className='nav-item' onClick={handleClick}>
              <Link to='/advertisement' className='nav-links' onClick={handleClick}>
                Advertisement
              </Link>
            </li>
            <li className='nav-item' onClick={handleClick}>
              <Link to='/terms' className='nav-links' onClick={handleClick}>
                Terms of Use
              </Link>
            </li>
            <li className='nav-item' onClick={handleClick}>
              <Link to='/privacy' className='nav-links' onClick={handleClick}>
                Privacy
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
