import React from 'react'
import Advertisement from "./Advertisement.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "../../ScrollIntoView/ScrollToTop"
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';


export default function AdvertisementPage() {

    
    return (
        <div >
        <center>
        <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <ScrollToTop />
        <Navbar/>
        <Advertisement/>
        <Footer/>  
        </center>
        </div>
    )
}



