import './Advertisement.css';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import firebase from 'firebase';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import 'reactjs-popup/dist/index.css';
import { Container } from "reactstrap";
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyBunF4W9sZ0tjCdgcrIw-tZZq2DWd_fGbI",
  authDomain: "konjo-18eba.firebaseapp.com",
  databaseURL: "https://konjo-18eba.firebaseio.com",
  projectId: "konjo-18eba",
  storageBucket: "konjo-18eba.appspot.com",
  messagingSenderId: "176880470815",
  appId: "1:176880470815:web:0eb3c8948a23fe8204fe2a",
  measurementId: "G-0DNBZ7Y7TS"
};

// Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }else {
    firebase.app(); // if already initialized, use that one
 }


function Advertisement() {

  const options = [
    "Ethiopia Bank",
    "PayPal",
    "Cash App",
    "Venmo"
  ];
  const adlength = [
    "1 week",
    "2 weeks",
    "1 month"
  ];

  var paymentOption = options[0];
  var adlengthOption = adlength[0];


  const { register, reset, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
        // Getinput values 
        
        let name = data.name
        let email = data.email
        let number = data.number
        let businessname = data.businessname
        let website = data.website
        let payment = paymentOption
        let length = adlengthOption
        let message = data.message
        paymentOption = options[0];
        adlengthOption = adlength[0];
      
        console.log(name, email, number, businessname, website, payment, length, message);
        saveContactInfo(name, email, number, businessname, website, payment, length, message)

        toast.dark('✅ Thanks, will talk to you soon!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
        };


function saveContactInfo(name, email, number, businessname, website, payment, length, message) {
  const data = {
    name,
    email,
    number,
    business: businessname,
    website,
    paymentMethod: payment, 
    adLength: length,
    description: message
};

console.log('Data being sent:', data);

axios.post("https://us-central1-konjo-18eba.cloudfunctions.net/sendAdvertisementEmail", data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
reset({})
};

const paymentfunc = (data) => {
  paymentOption = data.value
}

const lengthfunc = (data) => {
  adlengthOption = data.value
}

  return (
    <Container component="main" maxWidth="xs">
          <div>
      <ToastContainer className = "background-advertisement"
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      <ToastContainer />
    <h1 className='fa-times-red-center'> In App Advertisement</h1>
      <form id = "contact-form"  onSubmit={handleSubmit(onSubmit)}>
        <div class="advertisement-form input">

        <br /><label className='fa-medium-red-center' for="">Owner Name</label><br />
        {errors.name && <label className='fa-medium-warning'>{errors.name.message}</label>}
        <input name="name" type="text" placeholder="Name" {...register('name', { required: "Please include your name" })} /><br />

        <label className='fa-medium-red-center'for="">Owner Email</label><br />
        {errors.email && <label className='fa-medium-warning'>{errors.email.message}</label>}
        <input name="email" type="email" placeholder="Email" {...register('email', { required: "Please include your email" })}/><br />

        <label className='fa-medium-red-center'for="">Owner Number</label><br />
        {errors.number && <label className='fa-medium-warning'>{errors.number.message}</label>}
        <input name="number" type="phone" placeholder="Number" {...register('number', { required: "Please include your number" })}/><br />

        <label className='fa-medium-red-center' for="">Business Name</label><br />
        {errors.businessname && <label className='fa-medium-warning'>{errors.businessname.message}</label>}
        <input name="businessname" type="text" placeholder="Business Name" {...register('businessname', { required: "Please include business name" })} /><br />

        <label className='fa-medium-red-center' for="">Business Website</label><br />
        {errors.website && <label className='fa-medium-warning'>{errors.website.message}</label>}
        <input name="website" type="text" placeholder="Business Website" {...register('website', { required: "Please include business website" })} /><br />

        <label className='fa-medium-red-center' for="">Business Description</label><br />
        {errors.message && <label className='fa-medium-warning'>{errors.message.message}</label>}
        <textarea name="Description" id="" cols="30" rows="10" placeholder="Description" {...register('message', { required: "Please include a description" })} ></textarea>
        
        <label className='fa-medium-red-center' for=""> Payment Method</label><br />

        <Dropdown options={options} onChange={paymentfunc} value={paymentOption}/><br />

        <label className='fa-medium-red-center' for=""> Advertisement Length</label><br />
        <Dropdown  options={adlength} onChange={lengthfunc} value={adlengthOption} /><br />

        <button type="submit">Submit</button>

        </div>
      </form>
      <script src="https://www.gstatic.com/firebasejs/8.1.2/firebase-app.js"></script>
    </div>
    </Container>
  );
}

export default Advertisement;